<template>
    <div class="document">
        
        <button v-on:click="goToMenu()">Upload new file</button>
        <button v-on:click="goToUtilisateur()">Edit files</button>
        <img class="deconnexion" src="../assets/se-deconnecter.png" v-on:click="disconnect()" alt="Italian Trulli">
        <p style="margin-top:0px;" class="title">Document database</p>
        <div class="outer">
            <div id="jsp" class="buttons" v-for="item in listeCat" :key="item.message">
                <button class="button" name="testbutton" id="selector" v-on:click="selectCat(item)">{{ item }}</button>
            </div>
        </div>
        <div class="parent">
            <div v-if="selectedListe[0] == undefined">No result</div>
            <div class="child" v-for="item in selectedListe" :key="item.message">
                <div class="child_image" v-on:click="displayPopup(item)">
                    <img class="img1" :src="getImgUrl(item)" alt="Italian Trulli">
                    <div class="overlay-effect"></div>
                    
                <div class="center_img">
                    <img v-if="item.type === 'PDF'" class="img2" src="../assets/icon_PDF.png">
                    <img v-if="item.type === 'PPT'" class="img2" src="../assets/icon_PPT.png">
                    <img v-if="item.type === 'Video'" class="img2" src="../assets/icon_PLAY.png">
                </div>
                </div>
                <div class="child_text">
                    <div class="child_title">
                        <b v-on:click="displayPopup(item)">{{ item.titre }}</b>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="display" class="popup">
            <div class="popup-inner">
                <iframe v-if="selectedElement.type == 'PPT'" height="98%;" width="100%" frameborder="0" id="pptDisplay" src='https://view.officeapps.live.com/op/embed.aspx?src=https://api.fkv-vss.com/fichiers/vigilant_software_suite/Projets transverses-L3_InnovationLab (1).pptx'> </iframe>
                <iframe v-if="selectedElement.type == 'PDF'" id="pdfDisplay" height="98%;" width="100%" frameborder="0">xxxx</iframe>
                <video v-if="selectedElement.type == 'Video'" height="98%;" width="100%" controls>
                    <source id="video_here" class="video_here">
                        Your browser does not support HTML5 video.
                </video>
                <button class="close" v-on:click="undisplayPopup()">Close</button>
            </div>
        </div>
    </div>
</template>

<script>
var $ = require('jquery')
const axios = require('axios');
import router from '../router'
export default {
  mounted: async function () {
    var projects = await axios.get("https://api.fkv-vss.com/api/GetAllFichiers", {
    });
    const liste = projects.data.informations;
    this.selectedListe = liste
    this.liste = liste;
  },
    components: {},
    data() {
        return {
        listeCat: ["Vigilant Software Suite","Vigilant Master Med","Vigilant Sentinel","Vigilant Insight","Vigilant Bridge"
            ,"Vigilant Centerium","Partner"],
        listeCatAtt: ["vigilant_software_suite","vigilant_master_med","vigilant_sentinel"
                    ,"vigilant_insight","vigilant_bridge","centerium","partner"],
        liste: [],
        display: false,
        selectedListe: [],
        selectedElement: undefined,
        selectedCategorie: undefined
        };
    },
    methods: {
        async displayPopup(el) {
            this.selectedElement = el
            this.display = !this.display;

            if (!this.display) {
                return
            }


            this.$nextTick(() => {
                if (el.type == "PDF") {
                    document.getElementById('pdfDisplay').src = "https://api.fkv-vss.com/fichiers/" + el.categorie + "/" + el.nomDoc
                }
                else if (el.type == "Video") {
                    var $source = $('source#video_here.video_here')
                    $source[0].src = "https://api.fkv-vss.com/fichiers/" + el.categorie + "/" + el.nomDoc;
                    $source.parent()[0].load();
                }
                else {
                    document.getElementById('pptDisplay').src = 'https://view.officeapps.live.com/op/embed.aspx?src=' + "https://api.fkv-vss.com/fichiers/" + el.categorie + "/" + el.nomDoc
                }
            })
                
        },
        undisplayPopup() {
            this.display = !this.display;
        },
        selectCat: function (cat) {
            const nomCat = this.listeCatAtt[this.listeCat.findIndex((element) => element == cat)]
            if (this.selectedCategorie == nomCat) {
                this.selectedCategorie = undefined
                this.selectedListe = this.liste
                return 
            }
            this.selectedCategorie = nomCat
            this.selectedListe = this.liste.filter(file => file.categorie == nomCat)
            return
        },
        getImgUrl(item) {
            return "https://api.fkv-vss.com" + item.vignette.slice(1)
        },
        disconnect() {
            this.$session.destroy()
            router.push('/').catch(() => {})
        },
        goToMenu: function () {
            router.push('/Menu').catch(() => {})
        },
        goToUtilisateur: function () {
            router.push('/Utilisateur').catch(() => {})
        },
    }
}
</script>

<style scoped>
.title {
    text-align: left;
    font-size: 54px;
}
.button {
    width: 95%;
    height: 100%;
}
.buttons {
    display: block;
    flex-grow:1;
}
.outer {
    display:flex;
}
.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background-color: rgba(0,0,0,0.2);
    display: flex;
    align-items: center;
    justify-content: center;
}
.popup-inner {
    width: 75%;
    height: 80%;
    background: #FFF;
    padding: 32px;
}
.parent {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}
.child_image {
    background-color: #003862;
    height: 160px;
    border-radius: 8px;
    cursor: pointer;
}
.child_text {
    height: 15%;
    padding-top: 10px;
    text-align: left;
}
.child_title {
    font-size: 20px;
    color: #0063BE;
    cursor: pointer;
}
.child_desc {
    font-size: 14px;
    color: black;
    cursor: pointer;
}
.child {
    flex: 1 0 21%;
    margin: 15px;
    
    position: relative; 
    left: 0; 
    top: 0;
}
.child_image img {
    max-width:100%;
    max-height:100%;
}
.img1 {
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    border-radius: 8px;
}
.center_img {
    display: flex;
    justify-content: center;
}
.img2 {
    opacity: 0.7;
    z-index: 3;
    position: absolute;
    bottom: 40%;
    height: 40%;
    width: auto;
}
.overlay-effect {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.7;
  height: 160px;
  background-color: #003862;
  border-radius: 8px;
  z-index: 2;
}

@media only screen and (max-width: 820px) {
    .parent {
        grid-template-columns: repeat(1, 1fr);
    }
    .button {
        width: 95%;
    }
    button {
        font-size: 100%;
        width: 35%;
        margin: 3px;
        cursor: pointer;
    }
    .title {
        font-size: 190%;
        text-align: center
    }
    .outer {
        display: flex;
        flex-direction: column;
    }
    .buttons {
        margin-bottom: 2%;
    }
}
</style>